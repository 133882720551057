import React from "react";
import Footer from "./Footer";
import FooterNew from "./FooterNew";
import Navbar from "./Navbar";

export default function Main() {
  return (
    <>
      <Navbar />
      {/* <Footer /> */}
      <FooterNew />
    </>
  );
}
